import { Container } from '@mui/material';
import { ReferenceScreen } from '../../features/references/ReferenceScreen';

export const Fields = () => {
  return (
    <Container component="main" maxWidth="xs">
      <ReferenceScreen addBtnText="ДОДАТИ ПОЛЕ" slug="fields" />
    </Container>
  );
};
