import { Drivers } from '../views/references/Drivers';
import { Fields } from '../views/references/Fields';
import { Measurements } from '../views/references/Measurements';
import { PLants } from '../views/references/Plants';
import { Positions } from '../views/references/Positions';
import { Vehicles } from '../views/references/Vehicles';
import { RoutesEnum } from './routes';

export const REFERENCES = [
  {
    link: `${RoutesEnum.references}/positions`,
    label: 'Посади',
    element: Positions,
  },
  {
    link: `${RoutesEnum.references}/drivers`,
    label: 'Водії',
    element: Drivers,
  },
  {
    link: `${RoutesEnum.references}/vehicles`,
    label: 'Транспортні засоби',
    element: Vehicles,
  },
  {
    link: `${RoutesEnum.references}/fields`,
    label: 'Поля',
    element: Fields,
  },
  {
    link: `${RoutesEnum.references}/cultivated-plants`,
    label: 'Культура',
    element: PLants,
  },
  {
    link: `${RoutesEnum.references}/unit-measurements`,
    label: 'Одиниці Виміру',
    element: Measurements,
  },
];
