import { Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { REFERENCES } from '../../constants/references';

export const ReferencesList = () => {
  return (
    <Grid item xs={12} sx={{ paddingTop: 3 }}>
      <Grid container justifyContent="center" spacing={2}>
        {REFERENCES?.map((value) => (
          <Grid key={value.label} item>
            <Link to={'/' + value.link} style={{ textDecoration: 'none' }}>
              <Paper
                elevation={2}
                sx={{
                  height: 90,
                  width: 140,
                  backgroundColor: '#d0d0d012',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  ':hover, :active': {
                    opacity: 0.7,
                  },
                }}
              >
                <Typography variant="h6" component="h6">
                  {value.label}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
