import { apiSlice } from '../../app/api/apiSlice';
import { ReferenceOptionsType, ReferencesSlug } from '../../types/common';

export const referencesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReferences: builder.query<ReferenceOptionsType[], { slug: ReferencesSlug; query?: string }>({
      query: ({ slug, query }) => ({
        url: `reference/${slug}${query ?? ''}`,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'References', id: 'LIST' + arg.slug },
              ...result?.map(({ id }) => ({ type: 'References' as const, id: id + arg.slug })),
            ]
          : [{ type: 'References', id: 'LIST' + arg.slug }],
    }),
    addReference: builder.mutation({
      query: ({ slug, ...data }) => ({
        url: `reference/${slug}`,
        method: 'POST',
        body: data,
      }),

      invalidatesTags: (result, error, arg) => [{ type: 'References', id: 'LIST' + arg.slug }],
    }),
    updateReference: builder.mutation({
      query: ({ slug, ...data }) => ({
        url: `reference/${slug}/${data?.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'References', id: arg?.id + arg.slug }],
    }),
    deleteReference: builder.mutation({
      query: ({ slug, id }) => ({
        url: `reference/${slug}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'References', id: 'LIST' + arg.slug }],
    }),
  }),
});

export const {
  useGetReferencesQuery,
  useUpdateReferenceMutation,
  useAddReferenceMutation,
  useDeleteReferenceMutation,
} = referencesApiSlice;
