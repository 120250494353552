import { Container } from '@mui/material';
import ProfileEditForm from '../../features/profile/ProfileEditForm';
import { Loaader } from '../../components/Loader';
import { useGetProfileUserQuery } from '../../features/profile/profileApiSlice';
import { useGlobalError } from '../../hooks/useGlobalError';

const Profile = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetProfileUserQuery();

  useGlobalError(isError, error);

  return (
    <Container component="main" maxWidth="xs">
      {isLoading ? <Loaader /> : isSuccess && !!data && <ProfileEditForm data={data} />}
    </Container>
  );
};

export default Profile;
