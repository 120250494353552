import { useSelector } from 'react-redux';
import { selectCurrentRole } from '../features/auth/authSlice';

export const useRoles = () => {
  const userRole = useSelector(selectCurrentRole);

  const isAdmin = userRole === 'administrator';
  const isAgronomist = userRole === 'agronomist';
  const isSecurityMobile = userRole === 'security_mobile';
  const isSecurityHpp = userRole === 'security_hpp';
  const isController = userRole === 'controller';

  return { isAdmin, isAgronomist, isSecurityHpp, isSecurityMobile, isController };
};
