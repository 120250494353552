import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, selectCurrentRole, selectCurrentUserRoles } from '../features/auth/authSlice';
import { getMenu } from '../utils/menu';
import { Icon, Typography } from '@mui/material';
import { translateRole } from '../utils/text';
import { RoutesEnum } from '../constants/routes';
import { Roles } from '../types/auth';
import { NetworkStatusIndicator } from './NetworkStatusIndicator';

const CurrentRoleText = ({ role }: { role: Roles | null }) => (
  <Typography
    color={'#000'}
    variant="body2"
    sx={{
      px: { xs: 0.5, sm: 1 },
      fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
      mb: 0.5,
    }}
  >
    Роль: {translateRole(role)}
  </Typography>
);

const ResponsiveAppBar = () => {
  const dispatch = useDispatch();

  const role = useSelector(selectCurrentRole);
  const roles = useSelector(selectCurrentUserRoles);

  const hasMoreThanOneRole = roles?.length && roles.length > 1;

  return (
    <>
      <AppBar
        sx={{
          boxShadow: { xs: 'none', md: 2 },
          backgroundColor: 'white',
          top: 0,
          position: 'static',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ px: { xs: 0, sm: 2 }, display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar disableGutters sx={{ minHeight: 0, justifyContent: 'space-between' }}>
            <IconButton arial-label="menu" color="default" sx={{ p: 1, width: 40, height: 40 }}>
              <Link to={'/'}>
                <HomeIcon />
              </Link>
            </IconButton>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
            >
              {role &&
                getMenu(role).map((item) => (
                  <Button
                    component={NavLink}
                    key={item.link}
                    to={item.link}
                    sx={{
                      px: 1.5,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      color: 'text.primary',
                      '&.active': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    {item.appBarLabel}
                  </Button>
                ))}
            </Box>
            <Box
              sx={{
                a: {
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  width: 'fit-content',
                  gap: 0.2,
                },
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                marginX: 1,
              }}
            >
              {hasMoreThanOneRole ? (
                <Link to={'/' + RoutesEnum.chooseRole}>
                  <CurrentRoleText role={role} />
                  <NavigateNextIcon
                    sx={{
                      fontSize: {
                        xs: '0.9rem',
                        sm: '1.2rem',
                        paddingBottom: '2px',
                      },
                    }}
                    htmlColor="#000"
                  />
                </Link>
              ) : (
                <CurrentRoleText role={role} />
              )}
              <NetworkStatusIndicator />
            </Box>
            <IconButton
              onClick={() => dispatch(logOut({}))}
              arial-label="menu"
              color="default"
              sx={{ p: 1 }}
            >
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
          <Box
            sx={{
              a: {
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                width: 'fit-content',
                gap: 0.2,
              },
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            {hasMoreThanOneRole ? (
              <Link to={'/' + RoutesEnum.chooseRole}>
                <CurrentRoleText role={role} />
                <NavigateNextIcon
                  sx={{
                    fontSize: '1.4rem',
                    paddingBottom: '2px',
                  }}
                  htmlColor="#000"
                />
              </Link>
            ) : (
              <CurrentRoleText role={role} />
            )}
            <NetworkStatusIndicator />
          </Box>
        </Container>
      </AppBar>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: '#e9e9e9',
          bottom: 0,
          top: 'auto',
          position: 'fixed',
          display: { md: 'none' },
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            px: { xs: 0, sm: 2 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Toolbar disableGutters sx={{ minHeight: 0, mt: 0.5 }}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-around' }}>
              {role &&
                getMenu(role).map((item) => (
                  <Button
                    component={NavLink}
                    key={item.link}
                    to={item.link}
                    sx={{
                      px: 1,
                      minWidth: { xs: 'auto', sm: 64 },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      fontSize: { xs: '0.7rem', sm: '0.8rem' },
                      color: 'text.primary',
                      '&.active': {
                        color: 'primary.main',
                        svg: {
                          fill: '#0c99ff',
                        },
                      },
                    }}
                  >
                    <Icon sx={{ color: 'text.primary' }}>{item.icon}</Icon>
                    {item.appBarLabel}
                  </Button>
                ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default ResponsiveAppBar;
