import { Delete } from '@mui/icons-material';
import { IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { RoutesEnum } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { Harvest } from '../../types/harvest';
import { ReferenceOptionsType } from '../../types/common';
import { formatDate, translateStatus } from '../../utils/text';
import { useRoles } from '../../hooks/useRole';

interface IProps {
  item: Harvest;
  deleteHarvest: ({ id }: { id: number }) => void;
}

export const HarvestListItemAdmin = ({ item, deleteHarvest }: IProps) => {
  const { isAdmin, isAgronomist, isController } = useRoles();

  const navigate = useNavigate();

  const canEdit = isAdmin || (isAgronomist && !item.dispatched_at);

  const listItemProps = {
    ...((isAdmin || isController) && {
      secondaryAction: (
        <IconButton
          disabled={isController}
          edge="end"
          aria-label="delete"
          onClick={() => {
            deleteHarvest({ id: item.id });
          }}
        >
          <Delete color={isController ? 'disabled' : 'error'} />{' '}
        </IconButton>
      ),
    }),
  };

  return (
    <ListItem {...listItemProps} sx={{ justifyContent: 'space-between' }} disablePadding>
      <ListItemButton
        onClick={() => navigate(`/${RoutesEnum.harvest}/${item.id}`)}
        disabled={!canEdit && !isController}
      >
        <ListItemText
          sx={{
            display: 'inline-block',
          }}
          primary={
            <>
              <b>
                {(item.vehicle as ReferenceOptionsType)?.name} | {formatDate(item.created_at)}
              </b>
              <br />
              ТТН: {item.bill_of_lading}
              <br />
              <b>{translateStatus(item.status)}</b>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
