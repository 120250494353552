import { Container } from '@mui/material';
import { ReferencesList } from '../../features/references/ReferencesList';

export const References = () => {
  return (
    <Container component="main" maxWidth="xs">
      <ReferencesList />
    </Container>
  );
};
