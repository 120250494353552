import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSendLinkMutation } from './authApiSlice';
import { parseErrorObj } from '../../utils/text';

const ForgetPwd = () => {
  const userRef = useRef<HTMLDivElement | null>(null);
  const errRef = useRef<HTMLParagraphElement | null>(null);
  const [user, setUser] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [formChecked, setFormChecked] = useState(false);

  const [sendLink, { isLoading }] = useSendLinkMutation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormChecked(true);
    if (!user) return;

    try {
      await sendLink(user).unwrap();
      setSuccess(true);
      setFormChecked(false);
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErrMsg('No Server Response');
      } else {
        !!err.data?.detail ? setErrMsg(parseErrorObj(err)) : setErrMsg('Request failed');
      }
      errRef.current?.focus();
    }
  };

  const handleUserInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUser(e.target.value);
    setSuccess(false);
  };

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {success ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <svg width={115} xmlns="http://www.w3.org/2000/svg" viewBox="2.5 5 20 15">
              <path
                d="m9.688 15.898-3.98-3.98a1 1 0 0 0-1.415 1.414L8.98 18.02a1 1 0 0 0 1.415 0L20.707 7.707a1 1 0 0 0-1.414-1.414l-9.605 9.605z"
                fill="#2cbb5d"
              />
            </svg>
            <Typography variant="body1" sx={{ color: 'success.main', mt: 2 }}>
              Лист відправлено на email {user}
            </Typography>
          </Box>
        ) : (
          <>
            {' '}
            <Typography component="h1" variant="h5">
              Забули пароль?
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Введіть email"
                name="email"
                autoFocus
                onChange={handleUserInput}
                value={user}
                ref={userRef}
                error={formChecked && !user && !isLoading && !success}
              />

              <Box sx={{ display: 'inline' }}>
                {!!errMsg && (
                  <Typography
                    component="p"
                    variant="body2"
                    sx={{
                      color: 'error.main',
                      my: 2,
                      position: '',
                      margin: '0',
                    }}
                    ref={errRef}
                  >
                    {errMsg}
                  </Typography>
                )}
              </Box>

              <Button
                disabled={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: 'white' }}
              >
                Відновити пароль
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ForgetPwd;
