import { apiSlice } from '../../app/api/apiSlice';
import { User } from '../../types/users';
import { Profile } from '../../types/profile';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileUser: builder.query<User | null, void>({
      query: () => `users/profile`,
      providesTags: ['Profile'],
    }),
    updateProfileUser: builder.mutation<Profile, Profile>({
      query: (data) => ({
        url: `users/profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Profile'],
    }),
    changePassword: builder.mutation<void, { old_password: string; new_password: string }>({
      query: ({ old_password, new_password }) => ({
        url: `users/password`,
        method: 'PATCH',
        body: { old_password, new_password },
      }),
    }),
  }),
});

export const { useGetProfileUserQuery, useUpdateProfileUserMutation, useChangePasswordMutation } =
  profileApiSlice;
