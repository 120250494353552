import { useEffect } from 'react';
import { useGetReferencesQuery } from '../features/references/referencesApiSlice';
import { useGetUsersNamesQuery } from '../features/users/usersApiSlice';
import { ReferenceOptionsType } from '../types/common';
import { Harvest } from '../types/harvest';
import { BaseUserInfo } from '../types/users';
import { useGlobalError } from './useGlobalError';

type StateValueType = string | ReferenceOptionsType | BaseUserInfo | null;

interface IProps {
  skipFetchAgronomists: boolean;
  skipFetchPlants: boolean;
  skipFetchFields: boolean;
  skipFetchVehicles: boolean;
  skipFetchDrivers: boolean;
  data?: Harvest;
  setAgronomist: (value: React.SetStateAction<StateValueType>) => void;
  setPlant: (value: React.SetStateAction<StateValueType>) => void;
  setField: (value: React.SetStateAction<StateValueType>) => void;
  setVehicle: (value: React.SetStateAction<StateValueType>) => void;
  setDrivers: (value: React.SetStateAction<StateValueType>) => void;
}

export const useGetHarvestReferences = ({
  skipFetchAgronomists,
  skipFetchFields,
  skipFetchPlants,
  skipFetchVehicles,
  skipFetchDrivers,
  data,
  setAgronomist,
  setField,
  setPlant,
  setVehicle,
  setDrivers,
}: IProps) => {
  const {
    data: agronomists,
    isLoading: isLoadingAgronomists,
    isError: isErrorAgronomists,
    error: errorAgronomists,
  } = useGetUsersNamesQuery({ slug: 'agronomist' }, { skip: skipFetchAgronomists });
  const {
    data: plants,
    isLoading: isLoadingPlants,
    isError: isErrorPlants,
    error: errorPlants,
  } = useGetReferencesQuery({ slug: 'cultivated-plants' }, { skip: skipFetchPlants });
  const {
    data: fields,
    isLoading: isLoadingFields,
    isError: isErrorFields,
    error: errorFields,
  } = useGetReferencesQuery({ slug: 'fields' }, { skip: skipFetchFields });
  const {
    data: vehicles,
    isLoading: isLoadingVehicles,
    isError: isErrorVehicles,
    error: errorVehicles,
  } = useGetReferencesQuery({ slug: 'vehicles' }, { skip: skipFetchVehicles });

  const {
    data: drivers,
    isLoading: isLoadingDrivers,
    isError: isErrorDrivers,
    error: errorDrivers,
  } = useGetReferencesQuery({ slug: 'drivers' }, { skip: skipFetchDrivers });

  useEffect(() => {
    !skipFetchAgronomists &&
      !!agronomists?.data &&
      !!data?.user &&
      setAgronomist(agronomists?.data?.find((item) => item.id === data?.user?.id) || null);
  }, [agronomists?.data, data?.user]);

  useEffect(() => {
    !skipFetchPlants &&
      !!plants &&
      !!data?.cultivated_plant &&
      setPlant(plants?.find((item) => item.name === data?.cultivated_plant) || null);
  }, [plants, data?.cultivated_plant]);

  useEffect(() => {
    !skipFetchFields &&
      !!fields &&
      !!data?.field &&
      setField(fields?.find((item) => item.name === data?.field) || null);
  }, [fields, data?.field]);

  useEffect(() => {
    !skipFetchVehicles &&
      !!vehicles &&
      !!data?.vehicle &&
      setVehicle(vehicles?.find((item) => item.name === data?.vehicle) || null);
  }, [vehicles, data?.vehicle]);

  useEffect(() => {
    !skipFetchDrivers &&
      !!drivers &&
      !!data?.driver &&
      setDrivers(drivers?.find((item) => item.name === data?.driver) || null);
  }, [drivers, data?.driver]);

  useGlobalError(isErrorFields, errorFields);
  useGlobalError(isErrorPlants, errorPlants);
  useGlobalError(isErrorVehicles, errorVehicles);
  useGlobalError(isErrorDrivers, errorDrivers);
  useGlobalError(isErrorAgronomists, errorAgronomists);

  return {
    agronomists,
    fields,
    plants,
    vehicles,
    drivers,
    isLoadingAgronomists,
    isLoadingFields,
    isLoadingPlants,
    isLoadingVehicles,
    isLoadingDrivers,
  };
};
