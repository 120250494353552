import { apiSlice } from '../../app/api/apiSlice';
import { LIMIT_PER_PAGE_HERVEST } from '../../constants/harvest';
import { Harvest } from '../../types/harvest';

export const harvestApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHarvest: builder.query<
      { data: Harvest[]; total: number },
      {
        minDate: string;
        maxDate: string;
        page?: number;
        search?: string;
        offset?: number;
        limit?: number;
        refetch?: number;
      }
    >({
      query: ({ minDate, maxDate, page, search, offset, limit }) =>
        `harvest?offset=${page ? ((page || 1) - 1) * LIMIT_PER_PAGE_HERVEST : offset}&limit=${limit ?? LIMIT_PER_PAGE_HERVEST}${!!search ? `&search=${search}` : ''}&min_created_at=${new Date(new Date(minDate).valueOf() + new Date(minDate).getTimezoneOffset() * 60 * 1000).toISOString()}&max_created_at=${new Date(new Date(maxDate).valueOf() + (24 * 60 * 60 * 1000 - 1) + new Date(maxDate).getTimezoneOffset() * 60 * 1000).toISOString()}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Harvest', id: 'LIST' },
              ...result?.data?.map(({ id }) => ({ type: 'Harvest' as const, id })),
            ]
          : [{ type: 'Harvest', id: 'LIST' }],
    }),
    getHarvestById: builder.query<Harvest, { id: string | number }>({
      query: ({ id }) => `harvest/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Harvest', id: result?.id }],
    }),
    addHarvest: builder.mutation({
      query: (data) => ({
        url: 'harvest',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: 'LIST' }],
    }),
    updateHarvest: builder.mutation({
      query: (data) => ({
        url: `harvest/${data?.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: arg?.id }],
    }),
    dispatchHarvest: builder.mutation({
      query: ({ id }) => ({
        url: `harvest/${id}/dispatch`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: arg?.id }],
    }),
    deliverHarvest: builder.mutation({
      query: ({ id }) => ({
        url: `harvest/${id}/delivery`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: arg?.id }],
    }),
    unloadHarvest: builder.mutation({
      query: ({ id, data }) => ({
        url: `harvest/${id}/unload`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: arg?.id }],
    }),
    deleteHarvest: builder.mutation({
      query: ({ id }) => ({
        url: `harvest/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Harvest', id: arg.id }],
    }),
  }),
});

export const {
  useGetHarvestQuery,
  useAddHarvestMutation,
  useDeleteHarvestMutation,
  useGetHarvestByIdQuery,
  useUpdateHarvestMutation,
  useDispatchHarvestMutation,
  useDeliverHarvestMutation,
  useUnloadHarvestMutation,
} = harvestApiSlice;
