import { Container } from '@mui/material';
import InventoryList from '../../features/inventory/InventoryList';

const Inventory = () => {
  return (
    <Container component="main" maxWidth="xs">
      <InventoryList />
    </Container>
  );
};

export default Inventory;
