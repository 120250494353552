import { useNavigate } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="md" sx={{ textAlign: 'center', mt: 8 }}>
      <Box>
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Ой! Сторінку не знайдено.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Вибачте, але сторінка, яку ви шукаєте, не існує або була переміщена.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ mt: 4 }}>
          Повернутись на головну
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
