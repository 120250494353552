import React, { useEffect, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { parseErrorObj } from '../../utils/text';
import { useConfirmEmailChangeMutation } from './authApiSlice';
import { RoutesEnum } from '../../constants/routes';
import { CircularProgress } from '@mui/material';

const ChangeEmail = () => {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const errRef = useRef<HTMLParagraphElement | null>(null);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  const [confirmEmailChange, { isLoading }] = useConfirmEmailChangeMutation();

  const isUpdate = searchParams.get('update') === 'true';

  useEffect(() => {
    if (!code) return;

    const confirmEmail = async () => {
      try {
        await confirmEmailChange({ code, update: isUpdate }).unwrap();
        setSuccess(true);
      } catch (err: any) {
        console.log(err);
        if (!err?.data) {
          setErrMsg('No Server Response');
        } else {
          !!err.data?.detail ? setErrMsg(parseErrorObj(err)) : setErrMsg('Request failed');
        }
        errRef.current?.focus();
      }
    };
    confirmEmail();
  }, [code, confirmEmailChange]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : success ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <svg width={115} xmlns="http://www.w3.org/2000/svg" viewBox="2.5 5 20 15">
              <path
                d="m9.688 15.898-3.98-3.98a1 1 0 0 0-1.415 1.414L8.98 18.02a1 1 0 0 0 1.415 0L20.707 7.707a1 1 0 0 0-1.414-1.414l-9.605 9.605z"
                fill="#2cbb5d"
              />
            </svg>
            <Typography variant="body1" sx={{ color: 'success.main', mt: 2 }}>
              {isUpdate ? 'Email успішно змінено' : 'Email успішно підтверджено'}
            </Typography>
            <Button
              onClick={() => navigate('/' + RoutesEnum.login)}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Перейти на сторінку входу
            </Button>
          </Box>
        ) : (
          <>
            <Typography component="h1" variant="h5">
              Підтвердження зміни email
            </Typography>
            <Box sx={{ display: 'inline' }}>
              {!!errMsg && (
                <Typography
                  component="p"
                  variant="body2"
                  sx={{
                    color: 'error.main',
                    my: 2,
                    margin: '0',
                    width: '100%',
                  }}
                  ref={errRef}
                >
                  {errMsg}
                </Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ChangeEmail;
