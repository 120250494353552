import { Box, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import { useGetReferencesQuery } from './referencesApiSlice';
import { ReferencesSlug } from '../../types/common';
import { useRoles } from '../../hooks/useRole';
import { Loaader } from '../../components/Loader';
import { useGlobalError } from '../../hooks/useGlobalError';
import ReferenceAddItem from './ReferenceAddItem';
import ReferenceItem from './ReferenceItem';

interface IProps {
  addBtnText: string;
  slug: ReferencesSlug;
}

export const ReferenceScreen = ({ addBtnText, slug }: IProps) => {
  const { data, isSuccess, isLoading, isError, error } = useGetReferencesQuery({ slug });
  const { isAdmin } = useRoles();
  const disableEdit = !isAdmin;

  const [add, setAdd] = useState(false);

  useGlobalError(isError, error);

  const handleAddComplete = () => setAdd(false);

  return (
    <>
      {isLoading ? (
        <Loaader />
      ) : (
        isSuccess && (
          <>
            <List>
              {!disableEdit && (
                <ListItemButton
                  onClick={() => setAdd(true)}
                  sx={{ gap: 1, paddingY: 1.8, color: '#888' }}
                >
                  <Add sx={{ fontSize: '0.9rem' }} />
                  <ListItemText
                    primaryTypographyProps={{ fontWeight: 500, fontSize: '0.8rem' }}
                    primary={addBtnText}
                  />
                </ListItemButton>
              )}
              <Divider />
              {add && <ReferenceAddItem slug={slug} onAddComplete={handleAddComplete} />}
              {data?.map((item) => (
                <Fragment key={item.id}>
                  <ReferenceItem item={item} slug={slug} />
                  <Divider />
                </Fragment>
              ))}
              {!data?.length && (
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}
                >
                  <Typography variant="body2">Немає даних в довіднику</Typography>
                </Box>
              )}
            </List>
          </>
        )
      )}
    </>
  );
};
