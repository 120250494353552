import React, { useState } from 'react';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Box from '@mui/material/Box';

interface PasswordInputProps {
  password: string;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const PasswordInput = ({
  password,
  name,
  label,
  onChange,
  disabled,
  ...props
}: PasswordInputProps & TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        value={password}
        margin="normal"
        id={name}
        label={label}
        name={name}
        fullWidth
        disabled={disabled}
        sx={{ '& input': { paddingRight: 7 } }}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        {...props}
      />
      {!disabled && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            marginTop: 0.5,
            marginRight: 1,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )}
    </Box>
  );
};
export default PasswordInput;
