import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    sendLink: builder.mutation({
      query: (email: string) => ({
        url: `users/reset-password/send-link/${email}`,
        method: 'GET',
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ code, data }) => ({
        url: `users/reset-password/${code}`,
        method: 'POST',
        body: data,
      }),
    }),
    confirmEmailChange: builder.mutation({
      query: ({ code, update }) => ({
        url: `/users/confirm/email/${code}?${update ? 'update=true' : ''}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSendLinkMutation,
  useResetPasswordMutation,
  useConfirmEmailChangeMutation,
} = authApiSlice;
