import { Box, Button, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { RoutesEnum } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { Harvest } from '../../types/harvest';
import { ReferenceOptionsType } from '../../types/common';
import { useDeliverHarvestMutation, useDispatchHarvestMutation } from './harvestApiSlice';
import { useGlobalError } from '../../hooks/useGlobalError';
import { formatDate } from '../../utils/text';
import { useRoles } from '../../hooks/useRole';

interface IProps {
  item: Harvest;
}

export const HarvestListItemSecurity = ({ item }: IProps) => {
  const { isSecurityHpp, isSecurityMobile } = useRoles();

  const navigate = useNavigate();

  const [
    dispatchHarvest,
    { isLoading: isDispatching, isError: isErrorDispatching, error: errorDispatching },
  ] = useDispatchHarvestMutation();
  const [
    deliverHarvest,
    { isLoading: isDelivering, isError: isErrorDelivering, error: errorDelivering },
  ] = useDeliverHarvestMutation();

  const onDispatchClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      await dispatchHarvest({ id: item?.id }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const onDeliverClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      await deliverHarvest({ id: item?.id }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  useGlobalError(isErrorDispatching, errorDispatching);
  useGlobalError(isErrorDelivering, errorDelivering);

  return (
    <ListItem sx={{ display: 'flex' }} disablePadding>
      <ListItemButton
        onClick={() => {
          navigate(`/${RoutesEnum.harvest}/${item.id}`);
        }}
        disabled={!(isSecurityHpp && item.delivered_at)}
      >
        <ListItemText
          sx={{ whiteSpace: 'pre-line' }}
          primary={`Номер ТЗ: ${(item.vehicle as ReferenceOptionsType).name}, \n ТТН: ${item.bill_of_lading} \n Водій: ${(item.driver as ReferenceOptionsType)?.name} `}
        />
      </ListItemButton>
      <Box
        sx={{
          paddingLeft: 2,
          width: '100px',
          height: '80px',
          borderLeft: 'solid 1px rgba(0, 0, 0, 0.12)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isSecurityMobile &&
          (item?.dispatched_at ? (
            <Typography variant="body2">{formatDate(item.dispatched_at)}</Typography>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="success"
              sx={{ color: 'white', fontSize: '0.8rem' }}
              onClick={onDispatchClick}
              disabled={isDispatching}
            >
              Відправлено
            </Button>
          ))}
        {isSecurityHpp &&
          (item?.delivered_at ? (
            <Typography variant="body2">{formatDate(item.delivered_at)}</Typography>
          ) : item.dispatched_at ? (
            <Button
              variant="contained"
              color="success"
              sx={{ color: 'white', fontSize: '0.8rem' }}
              onClick={onDeliverClick}
              disabled={isDelivering}
            >
              Прибув
            </Button>
          ) : (
            <></>
          ))}
      </Box>
    </ListItem>
  );
};
