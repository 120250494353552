import { Container } from '@mui/material';
import UsersAddForm from '../../features/users/UserAddForm';

const UsersAdd = () => {
  return (
    <Container component="main" maxWidth="xs">
      <UsersAddForm />
    </Container>
  );
};

export default UsersAdd;
