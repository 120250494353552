import { createSlice } from '@reduxjs/toolkit';
import { AuthSlice, AuthUser } from '../../types/auth';
import { clearAuthDataFromLS, getAuthDataFromLS, setAuthDataToLS } from '../../utils/auth';
import { jwtDecode } from 'jwt-decode';

const getInitialState = (): AuthSlice => {
  const { accessToken, refreshToken, user, role } = getAuthDataFromLS();
  return {
    token: accessToken || null,
    forcePasswordChange: false,
    refresh: refreshToken || null,
    user: user || null,
    role: role || user?.roles?.[0] || null,
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    setCredentials: (state, action) => {
      const { access_token, refresh_token } = action.payload;
      const decodedJWT: AuthUser = jwtDecode(access_token);
      const role = decodedJWT?.roles?.[0];
      setAuthDataToLS(access_token, refresh_token, decodedJWT, role);

      state.user = decodedJWT;
      state.token = access_token;
      state.refresh = refresh_token;
      state.role = role;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      setAuthDataToLS(state.token, state.refresh, state.user, action.payload);
    },
    logOut: (state, action) => {
      clearAuthDataFromLS();

      state.user = null;
      state.token = null;
      state.refresh = null;
    },
    setForcePasswordChange: (state, action) => {
      state.forcePasswordChange = action.payload;
    },
  },
});

export const { setCredentials, logOut, setRole, setForcePasswordChange } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: { auth: AuthSlice }) => state.auth.user;
export const selectCurrentUserRoles = (state: { auth: AuthSlice }) => state.auth.user?.roles;
export const selectCurrentRole = (state: { auth: AuthSlice }) => state.auth.role;
export const selectCurrentToken = (state: { auth: AuthSlice }) => state.auth.token;
export const selectCurrentRefreshToken = (state: { auth: AuthSlice }) => state.auth.refresh;
export const selectForcePasswordChange = (state: { auth: AuthSlice }) =>
  state.auth.forcePasswordChange;
