import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { Errortype } from '../../types/common';

const initialState: (Errortype | SerializedError)[] = [];

const errorSlice = createSlice({
  name: 'errors',
  initialState: initialState,
  reducers: {
    addError: (state, action) => {
      state.push(action.payload);
    },
    deleteLastError: (state) => {
      state.pop();
    },
  },
});

export const { deleteLastError, addError } = errorSlice.actions;

export default errorSlice.reducer;

export const selectCurrentErrors = (state: { errors: (Errortype | SerializedError)[] }) =>
  state.errors;
