import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRole, selectCurrentUserRoles, setRole } from '../features/auth/authSlice';
import { translateRole } from '../utils/text';
import { useNavigate } from 'react-router-dom';

export const ChooseRole = () => {
  const userRoles = useSelector(selectCurrentUserRoles);
  const userRole = useSelector(selectCurrentRole);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: '100%',
        display: 'flex',
        flex: 1,
        alignItems: { xs: 'center', md: 'flex-start' },
        justifyContent: 'center',
        py: 3,
      }}
    >
      <FormControl>
        <FormLabel
          component={'p'}
          sx={{ color: '#000', fontWeight: 400, fontSize: '1.5rem' }}
          id="demo-radio-buttons-group-label"
        >
          Виберіть роль:{' '}
        </FormLabel>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={userRole}
          name="radio-buttons-group"
          onChange={(e) => dispatch(setRole(e.target.value))}
          sx={{ paddingRight: 4 }}
        >
          {userRoles?.map((r) => (
            <FormControlLabel key={r} value={r} control={<Radio />} label={translateRole(r)} />
          ))}
        </RadioGroup>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 3,
            mb: 2,
          }}
        >
          <Button role="button" onClick={() => navigate('/')} variant="contained">
            Ok
          </Button>
        </Box>
      </FormControl>
    </Container>
  );
};
