import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';
import errorReducer from '../features/errors/errorSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    errors: errorReducer,
  },
  middleware: (getDefaultMiddlware) => getDefaultMiddlware().concat(apiSlice.middleware),
  //TODO: delete on prod
  devTools: true,
});
