import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loaader } from '../../components/Loader';
import { useGetUsersQuery, useUpdateUserMutation } from './usersApiSlice';
import { Fragment, useState } from 'react';
import { useGlobalError } from '../../hooks/useGlobalError';
import { LIMIT_PER_PAGE_USERS } from '../../constants/users';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { RoutesEnum } from '../../constants/routes';
import { Add } from '@mui/icons-material';
import { User } from '../../types/users';
import { useRoles } from '../../hooks/useRole';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { InfiniteScrollTriggerDiv } from '../../components/InfiniteScrollTriggerDiv';

const UsersList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isAdmin } = useRoles();

  const [page, setPage] = useState(Number(searchParams.get('page') || '1'));
  const [fetchAfterUpdate, setFetchAfterUpdate] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [
    updateUser,
    { isError: isErrorUpdating, error: errorUpdating, isSuccess: isSuccessUpdating },
  ] = useUpdateUserMutation();

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
    isFetching,
    status,
  } = useGetUsersQuery(
    { offset: 0, limit: page * LIMIT_PER_PAGE_USERS },
    { skip: !isFirstLoad && !fetchAfterUpdate },
  );

  const {
    data: usersNext,
    isFetching: isFetchingNext,
    isSuccess: isSuccessNext,
    isError: isErrorNext,
    error: errorNext,
    status: statusNext,
    originalArgs: originalArgsNext,
  } = useGetUsersQuery(
    {
      page: page,
    },
    {
      skip: isFirstLoad,
    },
  );

  const canEdit = isAdmin;

  const handlePageChange = () => {
    setIsFirstLoad(false);
    setPage(page + 1);
    setSearchParams({
      page: (page + 1).toString(),
    });
  };

  const [combined, ref] = useInfiniteScroll<User>(
    LIMIT_PER_PAGE_USERS,
    users,
    isSuccess,
    isFetching,
    status,
    isSuccessNext,
    isFetchingNext,
    statusNext,
    originalArgsNext?.page,
    page,
    usersNext,
    isError,
    error,
    isErrorNext,
    errorNext,
    handlePageChange,
    setFetchAfterUpdate,
    isSuccessUpdating,
  );

  const handleChangeStatus = (value: boolean, user: User) => {
    updateUser({ id: user.id, active: value });
  };

  useGlobalError(isErrorUpdating, errorUpdating);

  return (
    <>
      {isLoading ? (
        <Loaader />
      ) : (
        <>
          <List>
            {canEdit && (
              <ListItemButton
                onClick={() => navigate('/' + RoutesEnum.addUser)}
                key={'add'}
                sx={{ gap: 1, paddingY: 1.8, color: '#888' }}
              >
                <Add sx={{ fontSize: '0.9rem' }} />
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 500, fontSize: '0.8rem' }}
                  primary="ДОДАТИ КОРИСТУВАЧА"
                />
              </ListItemButton>
            )}
            <Divider />
            {!combined?.total && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <Typography variant="body2">Немає користувачів</Typography>
              </Box>
            )}
            {combined?.data?.map((item) => {
              return (
                item && (
                  <Fragment key={item.id}>
                    <ListItem
                      sx={{ textDecoration: 'underline' }}
                      secondaryAction={
                        <Switch
                          disabled={!canEdit}
                          checked={item.active}
                          onChange={(e) => handleChangeStatus(e.target.checked, item)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => navigate(`/${RoutesEnum.users}/${item.id}`)}
                        sx={{
                          padding: 1.5,
                        }}
                      >
                        <ListItemText primary={`${item.last_name} ${item.first_name}`} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Fragment>
                )
              );
            })}
            <InfiniteScrollTriggerDiv
              divRef={ref}
              isFetching={isFetching}
              isFetchingNext={isFetchingNext}
              total={combined?.total}
              page={page}
              limit={LIMIT_PER_PAGE_USERS}
            />
          </List>
        </>
      )}
    </>
  );
};

export default UsersList;
