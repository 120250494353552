import { ReferenceOptionsType } from '../types/common';
import { BaseUserInfo } from '../types/users';

export type OptionType = ReferenceOptionsType | BaseUserInfo;

export const filterAndSortOptions = (
  options: OptionType[],
  inputValue: string,
  getOptionLabel: (option: OptionType) => string,
): OptionType[] => {
  if (inputValue.length < 2 && options.length > 10) {
    return [];
  }

  return options
    .filter((option) => getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => {
      const labelA = getOptionLabel(a).toLowerCase();
      const labelB = getOptionLabel(b).toLowerCase();
      return labelA.localeCompare(labelB, 'uk', { numeric: true });
    });
};
