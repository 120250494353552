import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Loaader } from '../../components/Loader';
import { useGlobalError } from '../../hooks/useGlobalError';
import UsersAddForm from '../../features/users/UserAddForm';
import { useGetUserByIdQuery } from '../../features/users/usersApiSlice';

const UserEdit = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } = useGetUserByIdQuery({ id: id || '' });

  useGlobalError(isError, error);

  return (
    <Container component="main" maxWidth="xs">
      {isLoading ? <Loaader /> : isSuccess && !!data && <UsersAddForm data={data} />}
    </Container>
  );
};

export default UserEdit;
