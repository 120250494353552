import { Button, Container, List, ListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, selectCurrentRole } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { getMenu } from '../utils/menu';

const Home = () => {
  const role = useSelector(selectCurrentRole);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: '100%',
        display: 'flex',
        flex: 1,
        alignItems: { xs: 'center', md: 'flex-start' },
      }}
    >
      <List sx={{ marginY: 3, flex: 1 }}>
        {role &&
          getMenu(role).map((item) => (
            <ListItem key={item.link}>
              <Button
                sx={{ color: 'white' }}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={() => navigate(item.link)}
              >
                {item.label}
              </Button>
            </ListItem>
          ))}
        <ListItem key="quit">
          <Button
            sx={{ color: 'white' }}
            variant="contained"
            fullWidth
            size="large"
            onClick={() => dispatch(logOut({}))}
          >
            Вихід з системи
          </Button>
        </ListItem>
      </List>
    </Container>
  );
};

export default Home;
