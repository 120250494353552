import { Outlet, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './AppBar';
import Box from '@mui/material/Box';
import { RoutesEnum } from '../constants/routes';

const Layout = () => {
  const { pathname } = useLocation();
  return (
    <Box sx={{ pb: { xs: 13, md: 5 } }}>
      {pathname !== '/' && pathname !== '/' + RoutesEnum.chooseRole && <ResponsiveAppBar />}
      <Outlet />
    </Box>
  );
};

export default Layout;
