import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useGlobalError } from './useGlobalError';
import { SerializedError } from '@reduxjs/toolkit';
import { useIntersection } from './useIntersection';

export const useInfiniteScroll = <T>(
  limit: number,
  data: { total: number; data: T[] } | undefined,
  isSuccess: boolean,
  isFetching: boolean,
  status: QueryStatus,
  isSuccessNext: boolean,
  isFetchingNext: boolean,
  statusNext: QueryStatus,
  originalArgsNextPage: number | undefined,
  page: number,
  dataNext:
    | {
        data: T[];
        total: number;
      }
    | undefined,

  isError: boolean,
  error: FetchBaseQueryError | SerializedError | undefined,
  isErrorNext: boolean,
  errorNext: FetchBaseQueryError | SerializedError | undefined,
  handlePageChange: () => void,
  setFetchAfterDeleteOrUpdate: (value: React.SetStateAction<boolean>) => void,
  isSuccessDeletingorUpdating: boolean,
): [{ total: number; data: T[] } | undefined, (node: HTMLDivElement) => void] => {
  const [combined, setCombined] = useState<{
    data: T[];
    total: number;
  }>();

  const [isVisible, ref] = useIntersection([combined?.data]);

  useEffect(() => {
    if (data && isSuccess && status === 'fulfilled') {
      setCombined(data);
      setFetchAfterDeleteOrUpdate(false);
    }
  }, [data, isSuccess, status]);

  useEffect(() => {
    if (isSuccessNext && statusNext === 'fulfilled' && !!combined && dataNext) {
      const arr = new Array(limit * page);
      arr.splice(0, limit * page, ...combined?.data.slice(0, limit * page));
      arr.splice(((originalArgsNextPage || 1) - 1) * limit, limit, ...dataNext?.data);
      setCombined({
        data: arr,
        total: dataNext?.total,
      });
    }
  }, [isSuccessNext, dataNext?.data, statusNext]);

  const onPageChange = () => {
    if (!combined?.total || combined.total <= page * limit || isFetching || isFetchingNext) return;
    handlePageChange();
  };

  useEffect(() => {
    if (isVisible) {
      onPageChange();
    }
  }, [isVisible]);

  useEffect(() => {
    if (isSuccessDeletingorUpdating) {
      setFetchAfterDeleteOrUpdate(true);
    }
  }, [isSuccessDeletingorUpdating]);

  useGlobalError(isError, error);
  useGlobalError(isErrorNext, errorNext);

  return [combined, ref];
};
