import { AuthUser, Roles } from '../types/auth';

export const getAuthDataFromLS = (): {
  accessToken?: string;
  refreshToken?: string;
  user?: AuthUser;
  role?: Roles;
} => {
  return localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') || '') : {};
};

export const setAuthDataToLS = (
  accessToken: string | null,
  refreshToken: string | null,
  user: AuthUser | null,
  role: Roles | null,
) => {
  localStorage.setItem('auth', JSON.stringify({ accessToken, refreshToken, user, role }));
};

export const clearAuthDataFromLS = () => {
  localStorage.removeItem('auth');
};
