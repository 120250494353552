import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface IProps {
  password: string;
}

type CriteriaKey = 'length' | 'uppercase' | 'lowercase' | 'digit' | 'specialChar';

const criteria: { name: CriteriaKey; description: string }[] = [
  {
    name: 'length',
    description: 'Мінімум 8 символів',
  },
  {
    name: 'uppercase',
    description: 'Мінімум одну велику літеру',
  },
  {
    name: 'lowercase',
    description: 'Мінімум одну маленьку літеру',
  },
  {
    name: 'digit',
    description: 'Мінімум одну цифру',
  },
  {
    name: 'specialChar',
    description: 'Мінімум один спеціальний символ (!, @, #, $, %, &)',
  },
];

const PasswordCriteria = ({ password }: IProps) => {
  const passwordCriteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    digit: /\d/.test(password),
    specialChar: /[!@#$%&]/.test(password),
  };

  return (
    <>
      {!!password && (
        <Box mt={0}>
          <Typography variant="body2">Пароль повинен містити:</Typography>
          {criteria.map((criterion) => (
            <Typography
              key={criterion.name}
              variant="body2"
              sx={{
                fontSize: '0.8rem',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {passwordCriteria[criterion.name] ? (
                <CheckIcon color="success" sx={{ fontSize: '1rem' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '1rem' }} />
              )}
              {criterion.description}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

export default PasswordCriteria;
