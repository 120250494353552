import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import HarvestAddForm from '../../features/harvest/HarvestAddForm';
import { useGetHarvestByIdQuery } from '../../features/harvest/harvestApiSlice';
import { Loaader } from '../../components/Loader';
import { useGlobalError } from '../../hooks/useGlobalError';

const HarvestEdit = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } = useGetHarvestByIdQuery({ id: id || '' });

  useGlobalError(isError, error);

  return (
    <Container component="main" maxWidth="xs">
      {isLoading ? <Loaader /> : isSuccess && !!data && <HarvestAddForm data={data} />}
    </Container>
  );
};

export default HarvestEdit;
