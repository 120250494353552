import { Roles } from '../types/auth';
import { RoutesEnum } from '../constants/routes';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import FireTruckIcon from '@mui/icons-material/FireTruck';
/*
import BallotIcon from '@mui/icons-material/Ballot';
*/
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const getMenu = (role: Roles) => {
  const menu = [];

  if (role === 'administrator' || role === 'controller') {
    menu.push({
      link: '/' + RoutesEnum.users,
      label: 'Користувачі',
      appBarLabel: 'Користувачі',
      icon: <PeopleAltIcon />,
    });
    menu.push({
      link: '/' + RoutesEnum.references,
      label: 'Довідники',
      appBarLabel: 'Довідники',
      icon: <CollectionsBookmarkIcon />,
    });
  }

  // (role === 'agronomist' || role === 'administrator' || role === 'controller') &&
  // menu.push({
  //   link: '/' + RoutesEnum.inventoryWriteOff,
  //   label: 'Акти списання',
  //   appBarLabel: 'Акти',
  //   icon: <BallotIcon />,
  // });

  menu.push({
    link: '/' + RoutesEnum.harvest,
    label: 'Збори врожаю',
    appBarLabel: 'Збори',
    icon: <FireTruckIcon />,
  });
  menu.push({
    link: '/' + RoutesEnum.profile,
    label: 'Профіль',
    appBarLabel: 'Профіль',
    icon: <AccountCircleIcon />,
  });

  return menu;
};
