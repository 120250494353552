import { Container } from '@mui/material';
import { ReferenceScreen } from '../../features/references/ReferenceScreen';

export const Vehicles = () => {
  return (
    <Container component="main" maxWidth="xs">
      <ReferenceScreen addBtnText="ДОДАТИ ТРАНСПОРТНИЙ ЗАСІБ" slug="vehicles" />
    </Container>
  );
};
