import { useSelector } from 'react-redux';
import { selectCurrentToken, selectForcePasswordChange } from './authSlice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RoutesEnum } from '../../constants/routes';

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const forcePasswordChange = useSelector(selectForcePasswordChange);
  const location = useLocation();

  return token && !forcePasswordChange ? (
    <Outlet />
  ) : forcePasswordChange && token ? (
    <Navigate
      to={'/' + RoutesEnum.changePwd + '/change_default'}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to={'/login'} state={{ from: location }} replace />
  );
};

export default RequireAuth;
