import { Autocomplete, TextField } from '@mui/material';
import { checkIfError } from '../../utils/text';
import { Errortype, ReferenceOptionsType } from '../../types/common';
import { BaseUserInfo } from '../../types/users';
import { filterAndSortOptions } from '../../utils/filterOptions';
import { DateTimePicker } from '@mui/x-date-pickers';

interface IProps {
  formChecked: boolean;
  value: string | BaseUserInfo | ReferenceOptionsType | null | Date;
  label: string;
  inputName: string;
  setNewValue: React.Dispatch<
    React.SetStateAction<string | ReferenceOptionsType | BaseUserInfo | null | Date>
  >;
  isLoading?: boolean;
  renderedValue?: string;
  required?: boolean;
  isAutocomplete?: boolean;
  options?: (ReferenceOptionsType | BaseUserInfo)[];
  getOptionLabel?: (option: ReferenceOptionsType | BaseUserInfo | string) => string;
  show?: boolean;
  skip?: boolean;
  disable?: boolean;
  disableAutocomplete?: boolean;
  err?: Errortype;
  type?: 'text' | 'number' | 'date';
  setErrorData?: React.Dispatch<React.SetStateAction<string | null>>;
  maxDate?: Date;
  minDate?: Date;
}

export const HarvestAddFormField = ({
  setNewValue,
  options,
  getOptionLabel,
  isLoading,
  label,
  inputName,
  renderedValue,
  value,
  formChecked,
  err,
  isAutocomplete = false,
  show = true,
  skip = false,
  disable = false,
  required = false,
  disableAutocomplete = false,
  type = 'text',
  setErrorData,
  maxDate,
  minDate,
}: IProps) => {
  return (
    <>
      {isAutocomplete ? (
        <>
          {show &&
            (skip ? (
              <TextField
                disabled={disable}
                value={renderedValue ?? (value as string)}
                required={disable ? false : required}
                fullWidth
                margin="normal"
                id={inputName}
                label={label}
                name={inputName}
                error={disable ? false : checkIfError(value, inputName, formChecked, err, required)}
              />
            ) : (
              options && (
                <Autocomplete
                  disabled={disable}
                  disablePortal
                  id="combo-box-demo"
                  loading={isLoading}
                  options={options}
                  getOptionLabel={getOptionLabel}
                  value={value as ReferenceOptionsType | BaseUserInfo | null}
                  fullWidth
                  onChange={(event: any, newValue: ReferenceOptionsType | BaseUserInfo | null) => {
                    setNewValue(newValue);
                  }}
                  filterOptions={
                    getOptionLabel
                      ? (options, { inputValue }) =>
                          filterAndSortOptions(options, inputValue, getOptionLabel)
                      : undefined
                  }
                  noOptionsText="Не знайдено"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={disable ? false : required}
                      fullWidth
                      margin="normal"
                      id={inputName}
                      label={label}
                      name={label}
                      error={
                        disable ? false : checkIfError(value, inputName, formChecked, err, required)
                      }
                    />
                  )}
                />
              )
            ))}
        </>
      ) : (
        show && (
          <>
            {type === 'date' ? (
              <DateTimePicker
                disabled={disable}
                label={label}
                name={inputName}
                value={value as Date}
                onChange={(newValue) => {
                  setNewValue(newValue);
                }}
                format={'dd.MM.yyyy HH:mm'}
                ampm={false}
                onError={(reason) => {
                  setErrorData && setErrorData(reason);
                }}
                maxDateTime={maxDate}
                minDateTime={minDate}
                timeSteps={{ minutes: 1 }}
              />
            ) : (
              <TextField
                disabled={disable}
                required={disable ? false : required}
                value={value}
                onChange={(e) => setNewValue(e.target.value)}
                margin="normal"
                id={inputName}
                label={label}
                name={inputName}
                fullWidth
                autoComplete={disableAutocomplete ? 'off' : 'on'}
                error={disable ? false : checkIfError(value, inputName, formChecked, err, required)}
                type={type}
              />
            )}
          </>
        )
      )}
    </>
  );
};
