import { FormEvent, useEffect, useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { Errortype, ReferenceOptionsType } from '../../types/common';
import { checkIfError, parseErrorObj } from '../../utils/text';
import { Link, useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../constants/routes';
import { useGetReferencesQuery } from '../references/referencesApiSlice';
import { User } from '../../types/users';
import { useGlobalError } from '../../hooks/useGlobalError';
import { useUpdateProfileUserMutation } from './profileApiSlice';
import { filterAndSortOptions } from '../../utils/filterOptions';

interface IProps {
  data: User;
}

const ProfileEditForm = ({ data }: IProps) => {
  const {
    data: positions,
    isLoading: isLoadingPositions,
    isError: isErrorPositions,
    error: errorPositions,
  } = useGetReferencesQuery({ slug: 'positions' });

  const [updateProfileUser, { isLoading }] = useUpdateProfileUserMutation();

  const navigate = useNavigate();

  const errRef = useRef<HTMLParagraphElement | null>(null);

  const [position, setPosition] = useState<ReferenceOptionsType | null>(
    positions?.find((item) => item.name === data?.position) || null,
  );
  const [first_name, setFirst_name] = useState(data?.first_name || '');
  const [last_name, setLast_name] = useState(data?.last_name || '');
  const [patronymic, setPatronymic] = useState(data?.patronymic || '');
  const [email, setEmail] = useState(data?.email || '');
  const [login] = useState(data?.login || '');
  const [phone, setPhone] = useState(data?.phone || '');
  const [formChecked, setFormChecked] = useState(false);
  const [err, setErr] = useState<Errortype>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormChecked(true);
    if (!first_name || !last_name || !email || !phone) return;
    try {
      const dataToSend = {
        login: login,
        first_name,
        last_name,
        patronymic,
        phone,
        email,
        ...(position && { position: position?.id }),
      };
      await updateProfileUser(dataToSend).unwrap();
      navigate('/');
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErr({ data: { detail: 'No Server Response' } });
      } else {
        !!err.data?.detail ? setErr(err) : setErr({ data: { detail: 'Request failed' } });
      }
      errRef.current?.focus();
    }
  };

  useEffect(() => {
    !!positions &&
      !position &&
      data?.position &&
      setPosition(positions?.find((item) => item.name === data?.position) || null);
  }, [positions, data]);

  useGlobalError(isErrorPositions, errorPositions);

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <TextField
              value={last_name}
              margin="normal"
              id="last_lame"
              label="Прізвище"
              name="last_lame"
              fullWidth
              required
              onChange={(e) => setLast_name(e.target.value)}
              error={checkIfError(last_name, 'last_name', formChecked, err, true)}
            />
            <TextField
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
              margin="normal"
              id="first_name"
              label="Ім'я"
              name="first_name"
              fullWidth
              error={checkIfError(first_name, 'first_name', formChecked, err, true)}
              required
            />
          </Box>
          <TextField
            value={patronymic}
            onChange={(e) => setPatronymic(e.target.value)}
            margin="normal"
            id="patronymic"
            label="По батькові"
            name="patronymic"
            fullWidth
            error={checkIfError(patronymic, 'patronymic', formChecked, err)}
          />
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            id="email"
            label="Email"
            name="email"
            fullWidth
            error={checkIfError(email, 'email', formChecked, err, true)}
            required
          />
          <TextField
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            id="phone"
            label="Телефон"
            name="phone"
            placeholder="+380... "
            fullWidth
            error={checkIfError(phone, 'phone', formChecked, err, true)}
            required
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            loading={isLoadingPositions}
            options={positions || []}
            getOptionLabel={(option: ReferenceOptionsType) => option.name}
            value={position}
            fullWidth
            onChange={(event: any, newValue: ReferenceOptionsType | null) => {
              setPosition(newValue);
            }}
            filterOptions={(options, { inputValue }) =>
              filterAndSortOptions(
                options,
                inputValue,
                (option) => (option as ReferenceOptionsType).name,
              ) as ReferenceOptionsType[]
            }
            noOptionsText="Не знайдено"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                id="position"
                label="Посада"
                name="position"
                error={checkIfError(position, 'position', formChecked, err, false)}
              />
            )}
          />
          <Box
            sx={{
              my: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {data?.id && <Typography variant="body1">Логін: {data?.login}</Typography>}
            <Link to={'/' + RoutesEnum.changePwd}>Змінити пароль</Link>
          </Box>
          <Box sx={{ display: 'inline' }}>
            {!!err && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                }}
                ref={errRef}
              >
                {parseErrorObj(err)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 3,
              mb: 2,
            }}
          >
            <Button onClick={() => navigate('/')}>Відмінити</Button>
            <Button disabled={isLoading} type="submit" variant="contained" sx={{ color: 'white' }}>
              Зберегти
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileEditForm;
