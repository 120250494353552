import { Box, Divider, IconButton, Input, ListItem } from '@mui/material';
import { Save } from '@mui/icons-material';
import { FormEvent, useRef, useState } from 'react';
import { useAddReferenceMutation } from './referencesApiSlice';
import { ReferenceOptionsType, ReferencesSlug } from '../../types/common';
import { useGlobalError } from '../../hooks/useGlobalError';
import { FieldArrayInput } from './FieldArrayInput';

interface AddItemProps {
  slug: ReferencesSlug;
  onAddComplete: () => void;
}

const ReferenceAddItem = ({ slug, onAddComplete }: AddItemProps) => {
  const [addReference, { isLoading, isError, error }] = useAddReferenceMutation();

  const [inputValueName, setInputValueName] = useState('');
  const [inputValueType, setInputValueType] = useState('');

  const [fieldArrayValue, setFieldArryValue] = useState<ReferenceOptionsType | null>(null);
  const [fieldArrayInputValue, setFieldArrayInputValue] = useState('');

  const formRef = useRef<HTMLFormElement>(null);

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await addReference({
        slug,
        name: inputValueName,
        ...(slug === 'unit-measurements' && { type: inputValueType }),
        ...(slug === 'fields' && { field_array: fieldArrayValue?.id }),
      }).unwrap();
      onAddComplete();
      setInputValueName('');
      setInputValueType('');
    } catch (e) {
      console.log(e);
    }
  };

  const handleFocusOut = (event: React.FocusEvent<HTMLFormElement, Element>) => {
    if (formRef.current && !formRef.current.contains(event.relatedTarget as Node)) {
      if (
        inputValueName === '' &&
        inputValueType === '' &&
        (fieldArrayInputValue === '' || !fieldArrayValue)
      ) {
        onAddComplete();
      }
    }
  };

  useGlobalError(isError, error);

  return (
    <>
      <ListItem>
        <Box
          component="form"
          ref={formRef}
          sx={{ display: 'flex', flex: 1 }}
          onSubmit={handleSave}
          noValidate
          onBlur={handleFocusOut}
        >
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Input
              value={inputValueName}
              onChange={(e) => setInputValueName(e.target.value)}
              autoFocus
              fullWidth
              sx={{ height: '40px', paddingY: '4px' }}
              error={isError}
              placeholder="назва"
              name={slug + ' name'}
            />
            {slug === 'unit-measurements' && (
              <Input
                value={inputValueType}
                onChange={(e) => setInputValueType(e.target.value)}
                fullWidth
                sx={{ height: '40px', paddingY: '4px' }}
                error={isError}
                placeholder="тип"
                name={slug + ' type'}
              />
            )}
            <FieldArrayInput
              fieldArrayValue={fieldArrayValue}
              setFieldArryValue={setFieldArryValue}
              fieldArrayInputValue={fieldArrayInputValue}
              setFieldArrayInputValue={setFieldArrayInputValue}
              slug={slug}
              err={isError}
            />
          </Box>
          <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
            <IconButton
              disabled={isLoading}
              onClick={handleSave}
              edge="end"
              aria-label="edit"
              type="submit"
            >
              <Save />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </>
  );
};

export default ReferenceAddItem;
