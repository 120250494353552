import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../constants/routes';
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { checkIfError, parseErrorObj } from '../../utils/text';
import { Errortype, ReferenceOptionsType } from '../../types/common';
import { useGlobalError } from '../../hooks/useGlobalError';
import { User } from '../../types/users';
import { useAddUserMutation, useDeleteUserMutation, useUpdateUserMutation } from './usersApiSlice';
import { Delete } from '@mui/icons-material';
import { Roles } from '../../types/auth';
import { useGetReferencesQuery } from '../references/referencesApiSlice';
import { useRoles } from '../../hooks/useRole';
import PasswordCriteria from '../../components/PasswordCriteria';
import { validatePasswordCriteria } from '../../utils/validatePasswordCriteria';
import PasswordInput from '../../components/PasswordInput';
import { filterAndSortOptions } from '../../utils/filterOptions';

interface IProps {
  data?: User;
}

const UsersAddForm = ({ data }: IProps) => {
  const {
    data: positions,
    isLoading: isLoadingPositions,
    isError: isErrorPositions,
    error: errorPositions,
  } = useGetReferencesQuery({ slug: 'positions' });

  const {
    data: roles,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
    error: errorRoles,
  } = useGetReferencesQuery({ slug: 'roles' });

  const [addUser, { isLoading: isAdding }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting, isError: isErrorDeleting, error: errorDeleting }] =
    useDeleteUserMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const errRef = useRef<HTMLParagraphElement | null>(null);
  const { isAdmin } = useRoles();

  const initialRoles = roles?.filter((item) => data?.roles?.includes(item.code as Roles)) || [];

  const [role, setRole] = useState<ReferenceOptionsType[]>(initialRoles);
  const [position, setPosition] = useState<ReferenceOptionsType | null>(
    positions?.find((item) => item.name === data?.position) || null,
  );
  const [first_name, setFirst_name] = useState(data?.first_name || '');
  const [last_name, setLast_name] = useState(data?.last_name || '');
  const [patronymic, setPatronymic] = useState(data?.patronymic || '');
  const [email, setEmail] = useState(data?.email || '');
  const [login, setLogin] = useState(data?.login || '');
  const [phone, setPhone] = useState(data?.phone || '');
  const [pwd, setPwd] = useState('');
  const [active, setActive] = useState(data?.active || false);
  const [formChecked, setFormChecked] = useState(false);
  const [err, setErr] = useState<Errortype>();

  const disableEdit = !isAdmin;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormChecked(true);
    if (!data?.id && (!pwd || !login)) return;
    if (!data?.id && !validatePasswordCriteria(pwd)) return;
    if (data?.id && !!pwd && !validatePasswordCriteria(pwd)) return;
    if (!first_name || !last_name || !email || !phone || !role?.length) return;
    try {
      const dataToSend = {
        login: login,
        ...(pwd && { password: pwd }),
        first_name,
        last_name,
        phone,
        email,
        active,
        patronymic,
        ...(position && { position: position?.id }),
        roles: role.filter((it) => !!it).map((it) => it?.id),
      };
      !!data
        ? await updateUser({ ...dataToSend, id: data.id }).unwrap()
        : await addUser(dataToSend).unwrap();
      location.key === 'default' ? navigate('/' + RoutesEnum.users) : navigate(-1);
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErr({ data: { detail: 'No Server Response' } });
      } else {
        !!err.data?.detail ? setErr(err) : setErr({ data: { detail: 'Request failed' } });
      }
      errRef.current?.focus();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteUser({ id }).unwrap();
      location.key === 'default' ? navigate('/' + RoutesEnum.users) : navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    !!roles && !!data?.roles?.length && setRole(initialRoles);
  }, [roles, data]);

  useEffect(() => {
    !!positions &&
      !position &&
      data?.position &&
      setPosition(positions?.find((item) => item.name === data?.position) || null);
  }, [positions, data]);

  useGlobalError(isErrorPositions, errorPositions);
  useGlobalError(isErrorRoles, errorRoles);
  useGlobalError(isErrorDeleting, errorDeleting);

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <TextField
              disabled={disableEdit}
              value={last_name}
              margin="normal"
              id="last_lame"
              label="Прізвище"
              name="last_lame"
              fullWidth
              required
              onChange={(e) => setLast_name(e.target.value)}
              error={checkIfError(last_name, 'last_name', formChecked, err, true)}
            />
            <TextField
              disabled={disableEdit}
              value={first_name}
              onChange={(e) => setFirst_name(e.target.value)}
              margin="normal"
              id="first_name"
              label="Ім'я"
              name="first_name"
              fullWidth
              error={checkIfError(first_name, 'first_name', formChecked, err, true)}
              required
            />
          </Box>
          <TextField
            disabled={disableEdit}
            value={patronymic}
            onChange={(e) => setPatronymic(e.target.value)}
            margin="normal"
            id="patronymic"
            label="По батькові"
            name="patronymic"
            fullWidth
            error={checkIfError(patronymic, 'patronymic', formChecked, err)}
          />
          <TextField
            disabled={disableEdit}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            id="new_email"
            label="Email"
            name="new_email"
            fullWidth
            error={checkIfError(email, 'email', formChecked, err, true)}
            required
            autoComplete="off"
          />
          <TextField
            disabled={disableEdit}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            id="phone"
            label="Телефон"
            name="phone"
            placeholder="+380... "
            fullWidth
            required
            error={checkIfError(phone, 'phone', formChecked, err, true)}
          />
          <Autocomplete
            disabled={disableEdit}
            disablePortal
            id="combo-box-demo"
            loading={isLoadingPositions}
            options={positions || []}
            getOptionLabel={(option: ReferenceOptionsType) => option.name}
            value={position}
            fullWidth
            onChange={(event: any, newValue: ReferenceOptionsType | null) => {
              setPosition(newValue);
            }}
            filterOptions={(options, { inputValue }): ReferenceOptionsType[] => {
              return filterAndSortOptions(
                options,
                inputValue,
                (option) => (option as ReferenceOptionsType).name,
              ) as ReferenceOptionsType[];
            }}
            noOptionsText="Не знайдено"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                id="position"
                label="Посада"
                name="position"
                error={checkIfError(position, 'position', formChecked, err)}
              />
            )}
          />
          <Autocomplete
            disabled={disableEdit}
            disablePortal
            id="combo-box-demo"
            loading={isLoadingRoles}
            options={roles || []}
            getOptionLabel={(option: ReferenceOptionsType) => option.name}
            multiple
            value={role}
            fullWidth
            onChange={(event: any, newValue: ReferenceOptionsType[]) => {
              setRole(newValue);
            }}
            filterOptions={(options, { inputValue }) =>
              filterAndSortOptions(
                options,
                inputValue,
                (option) => (option as ReferenceOptionsType).name,
              ) as ReferenceOptionsType[]
            }
            noOptionsText="Не знайдено"
            renderInput={(params) => (
              <TextField
                {...params}
                required
                margin="normal"
                fullWidth
                id="role"
                label="Роль"
                name="role"
                error={checkIfError(role.length, 'roles.0', formChecked, err, true)}
              />
            )}
          />
          {data?.id && (
            <>
              <Typography sx={{ marginTop: 1 }} variant="body1">
                Логін: {data?.login}
              </Typography>
              <PasswordInput
                autoComplete="off"
                disabled={disableEdit}
                password={pwd}
                name={'new_password'}
                label={'Новий пароль'}
                onChange={(e) => setPwd(e.target.value.trim())}
                error={
                  checkIfError(pwd, 'new_password', formChecked, err) ||
                  (formChecked && !!pwd && !validatePasswordCriteria(pwd))
                }
                inputProps={{
                  autocomplete: 'new_password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </>
          )}
          {!data?.id && (
            <>
              <TextField
                disabled={disableEdit}
                value={login}
                margin="normal"
                id="login"
                label="Логін"
                name="login"
                fullWidth
                required
                onChange={(e) => setLogin(e.target.value)}
                error={checkIfError(login, 'login', formChecked, err, true)}
              />
              <PasswordInput
                autoComplete="off"
                disabled={disableEdit}
                password={pwd}
                onChange={(e) => setPwd(e.target.value)}
                name={'new_password'}
                label={'Пароль'}
                error={
                  checkIfError(pwd, 'password', formChecked, err, true) ||
                  (formChecked && !validatePasswordCriteria(pwd))
                }
                required
                inputProps={{
                  autocomplete: 'new_password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </>
          )}
          <PasswordCriteria password={pwd} />
          <Box
            sx={{
              marginY: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              value="status"
              label="Статус: "
              labelPlacement="start"
              sx={{ margin: 0 }}
              control={
                <Switch
                  disabled={disableEdit}
                  onChange={(e) => setActive(e.target.checked)}
                  checked={active}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />

            {!!data?.id && (
              <IconButton
                disabled={isDeleting || disableEdit}
                aria-label="delete"
                onClick={() => handleDelete(data.id.toString())}
              >
                <Delete color={disableEdit ? 'disabled' : 'error'} />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: 'inline' }}>
            {!!err && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                }}
                ref={errRef}
              >
                {parseErrorObj(err)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 3,
              mb: 2,
            }}
          >
            <Button
              onClick={() =>
                location.key === 'default' ? navigate('/' + RoutesEnum.users) : navigate(-1)
              }
            >
              Відмінити
            </Button>
            <Button
              disabled={isAdding || isUpdating || disableEdit}
              type="submit"
              variant="contained"
              sx={{ color: 'white' }}
            >
              Зберегти
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UsersAddForm;
