import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut, setCredentials, setForcePasswordChange } from '../../features/auth/authSlice';
import { getAuthDataFromLS } from '../../utils/auth';
import { TAG_TYPES } from '../../constants/api';
import { addError } from '../../features/errors/errorSlice';
import { AuthResponse } from '../../types/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }: { getState: any }) => {
    const token = getState().auth.token;
    const role = getState().auth.role;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (role) {
      headers.set('CurrentRole', role);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  let result = await baseQuery(args, api, extraOptions);

  const data = result?.data as AuthResponse;
  if (
    (result?.error?.status === 403 &&
      (result?.error?.data as { code?: string })?.code === 'default_password') ||
    data?.force_change_password
  ) {
    api.dispatch(setForcePasswordChange(true));
  }

  if (
    result?.error?.status === 401 &&
    (result?.error?.data as { code?: string })?.code === 'token_expire'
  ) {
    console.log('sending refresh token');

    const refreshToken = getAuthDataFromLS()?.refreshToken;
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'POST',
        body: { refresh_token: refreshToken },
      },
      api,
      extraOptions,
    );

    if (refreshResult?.data) {
      api.dispatch(setCredentials({ ...refreshResult.data }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(addError(result.error));
      api.dispatch(logOut({}));
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: TAG_TYPES,
  endpoints: (builder) => ({}),
  refetchOnReconnect: true,
  keepUnusedDataFor: 0,
});
