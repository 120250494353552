import { useCallback, useEffect, useState } from 'react';

export const useIntersection = (
  dependancyArr: any[],
): [boolean, (node: HTMLDivElement) => void] => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (node) {
      const rect = node.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        node.style.display !== 'none';
      setIsVisible(isVisible);
    }
  }, [node]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Initial check on component mount
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, ...dependancyArr]);

  return [isVisible, ref];
};
