import { Container } from '@mui/material';
import { ReferenceScreen } from '../../features/references/ReferenceScreen';

export const Positions = () => {
  return (
    <Container component="main" maxWidth="xs">
      <ReferenceScreen addBtnText="ДОДАТИ ПОСАДУ" slug="positions" />
    </Container>
  );
};
