import { Container } from '@mui/material';
import HarvestList from '../../features/harvest/HarvestList';

const Harvest = () => {
  return (
    <Container component="main" maxWidth="xs">
      <HarvestList />
    </Container>
  );
};

export default Harvest;
