import { apiSlice } from '../../app/api/apiSlice';
import { LIMIT_PER_PAGE_USERS } from '../../constants/users';
import { BaseUserInfo, User } from '../../types/users';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      { data: User[]; total: number },
      { page?: number; offset?: number; limit?: number }
    >({
      query: ({ page, offset, limit }) =>
        `admin/users?offset=${page ? ((page || 1) - 1) * LIMIT_PER_PAGE_USERS : offset}&limit=${limit ?? LIMIT_PER_PAGE_USERS}`,

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Users', id: 'LIST' },
              ...result?.data?.map(({ id }) => ({ type: 'Users' as const, id })),
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getUserById: builder.query<User, { id: string | number }>({
      query: ({ id }) => `admin/users/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Users', id: result?.id }],
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: 'admin/users',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: 'LIST' }],
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `admin/users/${data?.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg?.id }],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `admin/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
    }),
    getUsersNames: builder.query<{ data: BaseUserInfo[]; total: number }, { slug: string }>({
      query: ({ slug }) => `users/${slug}`,

      providesTags: (result, error, arg) => [{ type: 'Users', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useGetUsersNamesQuery,
} = usersApiSlice;
