import * as React from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetPasswordMutation } from './authApiSlice';
import { parseErrorObj } from '../../utils/text';
import { RoutesEnum } from '../../constants/routes';
import PasswordCriteria from '../../components/PasswordCriteria';
import { validatePasswordCriteria } from '../../utils/validatePasswordCriteria';
import PasswordInput from '../../components/PasswordInput';

const ResetPwd = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  const pwdRef = useRef<HTMLDivElement | null>(null);
  const errRef = useRef<HTMLParagraphElement | null>(null);
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [formChecked, setFormChecked] = useState(false);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormChecked(true);

    if (!pwd) return;

    if (!validatePasswordCriteria(pwd)) return;

    try {
      await resetPassword({ code, data: { new_password: pwd } }).unwrap();
      navigate('/' + RoutesEnum.login);
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErrMsg('No Server Response');
      } else {
        !!err.data?.detail ? setErrMsg(parseErrorObj(err)) : setErrMsg('Request failed');
      }
      errRef.current?.focus();
    }
  };

  const handlePwdInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPwd(e.target.value);

  useEffect(() => {
    pwdRef.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [pwd]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Відновити пароль
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <PasswordInput
            password={pwd}
            label={'Введіть новий пароль'}
            name={'password'}
            required={true}
            autoFocus
            onChange={handlePwdInput}
            ref={pwdRef}
            error={(formChecked && !pwd) || (formChecked && !validatePasswordCriteria(pwd))}
          />
          <PasswordCriteria password={pwd} />
          <Box sx={{ display: 'inline' }}>
            {!!errMsg && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                  width: '100%',
                }}
                ref={errRef}
              >
                {errMsg}
              </Typography>
            )}
          </Box>
          <Button
            disabled={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: 'white' }}
          >
            Відновити пароль
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPwd;
