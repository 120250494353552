import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAddHarvestMutation,
  useUnloadHarvestMutation,
  useUpdateHarvestMutation,
} from './harvestApiSlice';
import { RoutesEnum } from '../../constants/routes';
import { Box, Button, CssBaseline, Typography } from '@mui/material';
import { FormEvent, useRef, useState } from 'react';
import { Harvest } from '../../types/harvest';
import {
  addLeadingZero,
  convertToUTC,
  formatDate,
  formatDateToLocal,
  parseErrorObj,
} from '../../utils/text';
import { Errortype, ReferenceOptionsType } from '../../types/common';
import { useSelector } from 'react-redux';
import { BaseUserInfo } from '../../types/users';
import { Roles } from '../../types/auth';
import { selectCurrentRole } from '../auth/authSlice';
import { HarvestAddFormField } from './HarvestAddFormField';
import { useGetHarvestReferences } from '../../hooks/useGetHarvestReferences';
import { useRoles } from '../../hooks/useRole';

const getIfSubmitBtnDisabled = (userRole?: Roles, data?: Harvest) => {
  if (userRole === 'controller') {
    return true;
  }
  if (userRole === 'agronomist' && !!data?.delivered_at) {
    return true;
  }

  return false;
};

type StateValueType = string | ReferenceOptionsType | BaseUserInfo | null | Date;

interface IProps {
  data?: Harvest;
}

const HarvestAddForm = ({ data }: IProps) => {
  const [addHarvest, { isLoading: isAdding }] = useAddHarvestMutation();
  const [updateHarvest, { isLoading: isUpdating }] = useUpdateHarvestMutation();
  const [unloadHarvest, { isLoading: isUnloading }] = useUnloadHarvestMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef<HTMLParagraphElement | null>(null);
  const userRole = useSelector(selectCurrentRole);

  const { isAdmin, isAgronomist, isController, isSecurityHpp } = useRoles();

  const skipFetchAgronomists = !isAdmin;
  const skipFetchPlants = isSecurityHpp || isController;
  const skipFetchFields = isSecurityHpp || isController;
  const skipFetchVehicles = isSecurityHpp || isController;
  const skipFetchDrivers = isSecurityHpp || isController;

  const disableDispatchInput = isController || isSecurityHpp;
  const disableDeliveredInput = isController || isSecurityHpp;
  const disableAgronomist = !isAdmin;
  const disableTTN = !isAdmin && !isAgronomist;
  const disableVehicle = !isAdmin && !isAgronomist;
  const disableDriver = !isAdmin && !isAgronomist;
  const disableField = !isAdmin && !isAgronomist;
  const disablePlant = !isAdmin && !isAgronomist;
  const disableWeight = !data?.delivered_at || isController;

  const showDispatchedAt = isAdmin || isSecurityHpp || isController;
  const showDeliveredAt = isAdmin || isSecurityHpp || isController;
  const showWeight = isAdmin || isSecurityHpp || isController;
  const showCreated_at = isAdmin || isSecurityHpp || isController;
  const showAgronomist = isAdmin || isSecurityHpp || isController;

  const [agronomist, setAgronomist] = useState<StateValueType>(
    skipFetchAgronomists ? data?.user || null : null,
  );
  const [vehicle, setVehicle] = useState<StateValueType>(
    skipFetchVehicles ? data?.vehicle || null : null,
  );

  const [driver, setDrivers] = useState<StateValueType>(
    skipFetchDrivers ? data?.driver || null : null,
  );

  const [field, setField] = useState<StateValueType>(skipFetchFields ? data?.field || null : null);
  const [plant, setPlant] = useState<StateValueType>(
    skipFetchPlants ? data?.cultivated_plant || null : null,
  );
  const [date, setDate] = useState<StateValueType>(
    data?.created_at ||
      `${new Date().getUTCFullYear()}-${addLeadingZero(new Date().getUTCMonth() + 1)}-${addLeadingZero(new Date().getUTCDate())}T${addLeadingZero(new Date().getUTCHours())}:${addLeadingZero(new Date().getUTCMinutes())}:${addLeadingZero(new Date().getUTCSeconds())}`,
  );

  const [ttn, setTtn] = useState<StateValueType>(data?.bill_of_lading || '');
  const [dispatched_at, setDispatched_at] = useState<StateValueType>(
    data?.dispatched_at ? new Date(formatDateToLocal(data?.dispatched_at)) : null,
  );
  const [delivered_at, setDelivered_at] = useState<StateValueType>(
    data?.delivered_at ? new Date(formatDateToLocal(data?.delivered_at)) : null,
  );
  const [weight, setWeight] = useState<StateValueType>(data?.weight || '');
  const [formChecked, setFormChecked] = useState(false);
  const [err, setErr] = useState<Errortype>();
  const [errorData, setErrorData] = useState<string | null>(null);

  const {
    agronomists,
    isLoadingAgronomists,
    fields,
    isLoadingFields,
    plants,
    isLoadingPlants,
    vehicles,
    isLoadingVehicles,
    drivers,
    isLoadingDrivers,
  } = useGetHarvestReferences({
    skipFetchAgronomists,
    skipFetchFields,
    skipFetchPlants,
    skipFetchVehicles,
    skipFetchDrivers,
    setAgronomist,
    setField,
    setPlant,
    setVehicle,
    setDrivers,
    data,
  });

  const isSubmitBtnDisabled =
    (userRole && getIfSubmitBtnDisabled(userRole, data)) || isAdding || isUpdating || isUnloading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormChecked(true);

    if (!ttn || !vehicle || !plant || !field) return;
    if (errorData !== null) return;
    if (isAdmin && data?.delivered_at && !delivered_at) return;
    if (isAdmin && data?.dispatched_at && !dispatched_at) return;
    if (isAdmin && !agronomist) return;
    if ((isSecurityHpp || isAdmin) && data?.delivered_at && !weight) return;

    try {
      if (isAdmin) {
        const dataToSend = {
          bill_of_lading: ttn,
          field: (field as ReferenceOptionsType)?.id,
          vehicle: (vehicle as ReferenceOptionsType)?.id,
          ...(driver && { driver: (driver as ReferenceOptionsType)?.id }),
          cultivated_plant: (plant as ReferenceOptionsType)?.id,
          agronomist: (agronomist as ReferenceOptionsType)?.id,
          ...(delivered_at && { delivered_at: convertToUTC((delivered_at as Date).toISOString()) }),
          ...(dispatched_at && {
            dispatched_at: convertToUTC((dispatched_at as Date).toISOString()),
          }),
          ...(delivered_at && { weight }),
        };

        !!data
          ? await updateHarvest({ ...dataToSend, id: data.id }).unwrap()
          : await addHarvest(dataToSend).unwrap();
        location.key === 'default' ? navigate('/' + RoutesEnum.harvest) : navigate(-1);
      } else if (isAgronomist) {
        const dataToSend = {
          bill_of_lading: ttn,
          field: (field as ReferenceOptionsType)?.id,
          vehicle: (vehicle as ReferenceOptionsType)?.id,
          ...(driver && { driver: (driver as ReferenceOptionsType)?.id }),
          cultivated_plant: (plant as ReferenceOptionsType)?.id,
        };

        !!data
          ? await updateHarvest({ ...dataToSend, id: data.id }).unwrap()
          : await addHarvest(dataToSend).unwrap();

        location.key === 'default' ? navigate('/' + RoutesEnum.harvest) : navigate(-1);
      } else {
        await unloadHarvest({ id: data?.id, data: { weight } }).unwrap();
        location.key === 'default' ? navigate('/' + RoutesEnum.harvest) : navigate(-1);
      }
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErr({ data: { detail: 'No Server Response' } });
      } else {
        !!err.data?.detail ? setErr(err) : setErr({ data: { detail: 'Request failed' } });
      }
      errRef.current?.focus();
    }
  };

  const getDefaultFormFieldProps = (
    label: string,
    inputName: string,
    value: StateValueType,
    setNewValue: React.Dispatch<React.SetStateAction<StateValueType>>,
  ) => {
    return {
      err,
      formChecked,
      label,
      inputName,
      value,
      setNewValue,
    };
  };

  const getAutocompleteDefaulpProps = (
    label: string,
    inputName: string,
    value: StateValueType,
    setNewValue: React.Dispatch<React.SetStateAction<StateValueType>>,
    required?: boolean,
  ) => {
    return {
      ...getDefaultFormFieldProps(label, inputName, value, setNewValue),
      required: required === undefined ? true : required,
      isAutocomplete: true,
    };
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <HarvestAddFormField
              {...getDefaultFormFieldProps(
                'Дата',
                'date',
                formatDate(date as string, true),
                setDate,
              )}
              show={showCreated_at}
              disable={true}
            />
            <HarvestAddFormField
              {...getDefaultFormFieldProps('ТТН', 'bill_of_lading', ttn, setTtn)}
              disable={disableTTN}
              required={true}
              disableAutocomplete={true}
            />
          </Box>
          <HarvestAddFormField
            {...getAutocompleteDefaulpProps('Агроном', 'agronomist', agronomist, setAgronomist)}
            renderedValue={`${(agronomist as BaseUserInfo)?.last_name} ${(agronomist as BaseUserInfo)?.first_name}`}
            disable={disableAgronomist}
            show={showAgronomist}
            skip={skipFetchAgronomists}
            isLoading={isLoadingAgronomists}
            options={agronomists?.data || []}
            getOptionLabel={(option) =>
              `${(option as BaseUserInfo).first_name} ${(option as BaseUserInfo).last_name}`
            }
          />
          <HarvestAddFormField
            {...getAutocompleteDefaulpProps('ТЗ', 'vehicle', vehicle, setVehicle)}
            disable={disableVehicle}
            skip={skipFetchVehicles}
            isLoading={isLoadingVehicles}
            options={vehicles || []}
            getOptionLabel={(option) => (option as ReferenceOptionsType)?.name}
          />
          <HarvestAddFormField
            {...getAutocompleteDefaulpProps('Водій', 'driver', driver, setDrivers, false)}
            disable={disableDriver}
            skip={skipFetchDrivers}
            isLoading={isLoadingDrivers}
            options={drivers || []}
            getOptionLabel={(option) => (option as ReferenceOptionsType)?.name}
          />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <HarvestAddFormField
              {...getAutocompleteDefaulpProps('Поле', 'field', field, setField)}
              disable={disableField}
              skip={skipFetchFields}
              isLoading={isLoadingFields}
              options={fields || []}
              getOptionLabel={(option) => (option as ReferenceOptionsType)?.name}
            />
            <HarvestAddFormField
              {...getAutocompleteDefaulpProps('Культура', 'cultivated_plant', plant, setPlant)}
              disable={disablePlant}
              skip={skipFetchPlants}
              isLoading={isLoadingPlants}
              options={plants || []}
              getOptionLabel={(option) => (option as ReferenceOptionsType)?.name}
            />
          </Box>
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <HarvestAddFormField
              {...getDefaultFormFieldProps(
                'Відправлено',
                'dispatched_at',
                dispatched_at,
                setDispatched_at,
              )}
              show={showDispatchedAt}
              disable={disableDispatchInput}
              required={!!data?.dispatched_at}
              type={'date'}
              setErrorData={setErrorData}
              maxDate={new Date()}
            />
            <HarvestAddFormField
              {...getDefaultFormFieldProps('Прибув', 'delivered_at', delivered_at, setDelivered_at)}
              show={showDeliveredAt}
              disable={disableDeliveredInput}
              required={!!data?.delivered_at}
              type={'date'}
              setErrorData={setErrorData}
              maxDate={new Date()}
              minDate={dispatched_at as Date}
            />
          </Box>
          <HarvestAddFormField
            {...getDefaultFormFieldProps('Фізична вага, кг', 'weight', weight, setWeight)}
            show={showWeight}
            formChecked={formChecked}
            disable={isAdmin && delivered_at ? false : disableWeight}
            required={!!data?.delivered_at}
            type="number"
          />
          <Box sx={{ display: 'inline' }}>
            {!!err && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                }}
                ref={errRef}
              >
                {parseErrorObj(err)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 3,
              mb: 2,
            }}
          >
            <Button
              onClick={() =>
                location.key === 'default' ? navigate('/' + RoutesEnum.harvest) : navigate(-1)
              }
            >
              Відмінити
            </Button>
            <Button
              disabled={isSubmitBtnDisabled}
              type="submit"
              variant="contained"
              sx={{ color: 'white' }}
            >
              Зберегти
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HarvestAddForm;
