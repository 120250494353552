import { Alert, Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCurrentErrors } from '../features/errors/errorSlice';
import { determineIfIsSerializedErr } from '../utils/text';

export const GlobalError = () => {
  const errors = useSelector(selectCurrentErrors);

  return (
    <>
      {!!errors.length && (
        <Box
          sx={{
            position: 'absolute',
            bottom: { xs: 'auto', md: '15px' },
            right: { xs: 0, md: '15px' },
            top: { xs: '15px', md: 'auto' },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Stack sx={{ width: '100%' }} spacing={2}>
            {errors.map((item, i) =>
              !determineIfIsSerializedErr(item) ? (
                Array.isArray(item.data?.detail) ? (
                  item.data?.detail.map((it, index) => (
                    <Alert key={'error' + i.toString() + index} severity="error">
                      {it.detail || 'Невідома помилка'}
                    </Alert>
                  ))
                ) : (
                  <Alert key={'error' + i} severity="error">
                    {item.data?.detail || 'Невідома помилка'}
                  </Alert>
                )
              ) : (
                <Alert key={'error' + i} severity="error">
                  {item.message || 'Невідома помилка'}
                </Alert>
              ),
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
