import { Box } from '@mui/material';

interface IProps {
  divRef: (node: HTMLDivElement) => void;
  isFetching: boolean;
  isFetchingNext: boolean;
  total: number | undefined;
  page: number;
  limit: number;
}

export const InfiniteScrollTriggerDiv = ({
  divRef,
  isFetching,
  isFetchingNext,
  total,
  page,
  limit,
}: IProps) => {
  return (
    <Box
      style={{
        height: '40px',
        display:
          !isFetching && !isFetchingNext && !!total && total > page * limit ? 'block' : 'none',
      }}
      ref={divRef}
    ></Box>
  );
};
