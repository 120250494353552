import { Container } from '@mui/material';
import HarvestAddForm from '../../features/harvest/HarvestAddForm';

const HarvestAdd = () => {
  return (
    <Container component="main" maxWidth="xs">
      <HarvestAddForm />
    </Container>
  );
};

export default HarvestAdd;
