import { Container } from '@mui/material';
import InventoryAddForm from '../../features/inventory/InventoryAddForm';

const InventoryAdd = () => {
  return (
    <Container component="main" maxWidth="xs">
      <InventoryAddForm />
    </Container>
  );
};

export default InventoryAdd;
