import { apiSlice } from '../../app/api/apiSlice';
import { LIMIT_PER_PAGE_USERS } from '../../constants/users';
import { Inventory, InventoryBaseInfo } from '../../types/inventory';

export const inventoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInventory: builder.query<{ data: InventoryBaseInfo[]; total: number }, { page: number }>({
      query: ({ page }) =>
        `inventory-write-off?offset=${((page || 1) - 1) * LIMIT_PER_PAGE_USERS}&limit=${LIMIT_PER_PAGE_USERS}`,

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Inventory-writes-off', id: 'LIST' },
              ...result?.data?.map(({ id }) => ({ type: 'Inventory-writes-off' as const, id })),
            ]
          : [{ type: 'Inventory-writes-off', id: 'LIST' }],
    }),
    getInventoryById: builder.query<Inventory, { id: string | number }>({
      query: ({ id }) => `inventory-write-off/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Inventory-writes-off', id: result?.id }],
    }),
    addInventory: builder.mutation({
      query: (data) => ({
        url: 'inventory-write-off',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Inventory-writes-off', id: 'LIST' }],
    }),
    updateInventory: builder.mutation({
      query: (data) => ({
        url: `inventory-write-off/${data?.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Inventory-writes-off', id: arg?.id }],
    }),
    deleteInventory: builder.mutation({
      query: ({ id }) => ({
        url: `inventory-write-off/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Inventory-writes-off', id: arg.id }],
    }),
  }),
});

export const {
  useAddInventoryMutation,
  useDeleteInventoryMutation,
  useGetInventoryByIdQuery,
  useGetInventoryQuery,
  useUpdateInventoryMutation,
} = inventoryApiSlice;
