import { Range } from 'react-date-range';
import { Errortype } from '../types/common';
import { SerializedError } from '@reduxjs/toolkit';
import { Roles } from '../types/auth';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import format from 'date-fns/format';
import uk from 'date-fns/locale/uk';

export const parseErrorObj = (err: Errortype | SerializedError) => {
  let error = '';
  if (determineIfIsSerializedErr(err)) {
    error = err.message || '';
  } else {
    if (Array.isArray(err?.data?.detail)) {
      error = err?.data?.detail.map((d) => d.detail).join(', ') || '';
    } else {
      error = err?.data?.detail || '';
    }
  }

  return error;
};

export const addLeadingZero = (num: number) => (num >= 0 && num <= 9 ? `0${num}` : num);

export const getDateInYYMMDD = (date: Date) => {
  const yy = date.getFullYear();
  const mm = addLeadingZero(date.getMonth() + 1);
  const dd = addLeadingZero(date.getDate());
  return `${yy}-${mm}-${dd}`;
};

export const getDateRangeText = (range: Range[]) => {
  const today = getDateInYYMMDD(new Date()).replaceAll('-', '/');
  const endD = getDateInYYMMDD(new Date(range[0].endDate || '')).replaceAll('-', '/');
  const startD = getDateInYYMMDD(new Date(range[0].startDate || '')).replaceAll('-', '/');

  return endD === startD && endD === today
    ? 'Сьогодні'
    : endD === startD
      ? endD
      : `${endD} - ${startD}`;
};

export const determineIfIsSerializedErr = (
  toBeDetermined: Errortype | SerializedError | FetchBaseQueryError,
): toBeDetermined is SerializedError => {
  if ((toBeDetermined as SerializedError).message) {
    return true;
  }
  return false;
};

export const checkIfError = (
  value: unknown,
  fieldName: string,
  formChecked: boolean,
  err?: Errortype,
  required?: boolean,
) => {
  return (
    (required && formChecked && !value) ||
    (Array.isArray(err?.data?.detail) &&
      !!err?.data?.detail?.find((i) => i.code === 'body.' + fieldName))
  );
};

export const translateRole = (role: Roles | null) => {
  switch (role) {
    case 'administrator':
      return 'Адміністратор';
    case 'agronomist':
      return 'Агроном';
    case 'controller':
      return 'Контролер';
    case 'security_hpp':
      return 'Охорона ХПП';
    case 'security_mobile':
      return 'Охорона мобільна';
    default:
      return '';
  }
};

export const translateStatus = (status?: string) => {
  switch (status) {
    case 'new':
      return 'Новий';
    case 'dispatched':
      return 'Відправлено';
    case 'delivered':
      return 'Прибув';
    case 'unloaded':
      return 'Розвантажено';
    default:
      return '';
  }
};

export const formatDate = (string?: string | null, short?: boolean) => {
  if (!string) return '';
  const today = new Date();
  const dt = new Date(string);
  const dtDateOnly = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
  const isCurrYear = today.getFullYear() === dtDateOnly.getFullYear();
  const isToday =
    isCurrYear &&
    today.getMonth() === dtDateOnly.getMonth() &&
    today.getDate() === dtDateOnly.getDate();
  let formatedDate = format(dtDateOnly, 'yyyy, d MMMM, H:mm', { locale: uk });
  if (short) {
    formatedDate = format(dtDateOnly, 'dd.MM.yyyy H:mm', { locale: uk });
  } else if (isToday) {
    formatedDate = 'сьогодні, ' + format(dtDateOnly, 'H:mm');
  } else if (isCurrYear) {
    formatedDate = format(dtDateOnly, 'd MMMM, H:mm', { locale: uk });
  }
  return formatedDate;
};

export const formatDateToLocal = (string?: string | null) => {
  if (!string) return '';
  const dt = new Date(string);
  const dtDateOnly = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, "yyyy-MM-dd'T'HH:mm", { locale: uk });
};

export const convertToUTC = (localDateString?: string | null) => {
  if (!localDateString) return '';
  const localDate = new Date(localDateString);
  const year = localDate.getUTCFullYear();
  const month = (localDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = localDate.getUTCDate().toString().padStart(2, '0');
  const hours = localDate.getUTCHours().toString().padStart(2, '0');
  const minutes = localDate.getUTCMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
