import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './features/auth/Login';
import RequireAuth from './features/auth/RequireAuth';
import Home from './views/Home';
import Users from './views/users/Users';
import ForgetPwd from './features/auth/ForgetPwd';
import { RoutesEnum } from './constants/routes';
import Harvest from './views/harvest/Harvest';
import HarvestAdd from './views/harvest/HarvestAdd';
import HarvestEdit from './views/harvest/HarvestEdit';
import { GlobalError } from './components/GlobalError';
import UsersAdd from './views/users/UsersAdd';
import UserEdit from './views/users/UserEdit';
import ResetPwd from './features/auth/ResetPwd';
import { References } from './views/references/References';
import { REFERENCES } from './constants/references';
import Inventory from './views/inventory/Inventory';
import InventoryAdd from './views/inventory/InventoryAdd';
import { ChooseRole } from './views/ChooseRole';
import Profile from './views/profile/Profile';
import ChangePwd from './views/profile/ChangePwd';
import NotFound from './views/NotFound';
import ChangeEmail from './features/auth/ChangeEmail';
import { useEffect } from 'react';
import { clearExpiredCache } from './utils/clearExpiredcache';

function App() {
  useEffect(() => {
    clearExpiredCache();
  }, []);

  return (
    <>
      {' '}
      <Routes>
        {' '}
        {/* public routes */}
        <Route path={RoutesEnum.login} element={<Login />} />
        <Route path={RoutesEnum.forgotPwd} element={<ForgetPwd />} />
        <Route path={`${RoutesEnum.forgotPwd}/:code`} element={<ResetPwd />} />
        <Route path={`${RoutesEnum.changePwd}/:change_default`} element={<ChangePwd />} />
        <Route path={`${RoutesEnum.changeEmail}/:code`} element={<ChangeEmail />} />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Layout />}>
            {/* protected routes*/}

            <Route index element={<Home />} />
            <Route path={RoutesEnum.chooseRole} element={<ChooseRole />} />
            <Route path={RoutesEnum.users} element={<Users />} />
            <Route path={RoutesEnum.addUser} element={<UsersAdd />} />
            <Route path={`${RoutesEnum.users}/:id`} element={<UserEdit />} />
            <Route path={RoutesEnum.harvest} element={<Harvest />} />
            <Route path={RoutesEnum.addHarvest} element={<HarvestAdd />} />
            <Route path={`${RoutesEnum.harvest}/:id`} element={<HarvestEdit />} />
            <Route path={RoutesEnum.references} element={<References />} />
            <Route path={RoutesEnum.profile} element={<Profile />} />
            <Route path={RoutesEnum.changePwd} element={<ChangePwd />} />
            <Route path={RoutesEnum.inventoryWriteOff} element={<Inventory />} />
            <Route path={RoutesEnum.inventoryWriteOffAdd} element={<InventoryAdd />} />
            {REFERENCES.map((item) => (
              <Route key={item.link} path={item.link} element={<item.element />} />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <GlobalError />
    </>
  );
}

export default App;
