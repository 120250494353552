import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loaader } from '../../components/Loader';
import { useGlobalError } from '../../hooks/useGlobalError';
import { LIMIT_PER_PAGE_USERS } from '../../constants/users';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  Typography,
} from '@mui/material';
import { RoutesEnum } from '../../constants/routes';
import { Add } from '@mui/icons-material';
import { useGetInventoryQuery } from './inventoryApiSlice';
import { ChangeEvent, Fragment, useState } from 'react';

const InventoryList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(Number(searchParams.get('page') || '1'));

  const { data: inventory, isLoading, isSuccess, isError, error } = useGetInventoryQuery({ page });

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);

    setSearchParams({
      page: value.toString(),
    });
  };

  useGlobalError(isError, error);

  return (
    <>
      {isLoading ? (
        <Loaader />
      ) : (
        isSuccess && (
          <>
            <List>
              <ListItemButton
                onClick={() => navigate('/' + RoutesEnum.inventoryWriteOffAdd)}
                key={'add'}
                sx={{ gap: 1, paddingY: 1.8 }}
              >
                <Add />
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 500 }}
                  primary="ДОДАТИ ЗАЯВКУ"
                />
              </ListItemButton>
              <Divider />
              {!inventory.total && (
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}
                >
                  <Typography variant="body2">Немає заявок </Typography>
                </Box>
              )}
              {inventory?.data?.map((item) => {
                return (
                  <Fragment key={item.id}>
                    <ListItem>
                      <ListItemText primary={`${item.name} `} />
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>

            {inventory?.total > LIMIT_PER_PAGE_USERS && (
              <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  page={page}
                  onChange={handlePageChange}
                  count={Math.ceil(inventory.total / LIMIT_PER_PAGE_USERS)}
                />
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};

export default InventoryList;
