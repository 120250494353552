import { Container } from '@mui/material';

import ChangePwdForm from '../../features/profile/ChangePwdForm';

const ChangePwd = () => {
  return (
    <Container component="main" maxWidth="xs">
      <ChangePwdForm />
    </Container>
  );
};

export default ChangePwd;
