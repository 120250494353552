import { useNavigatorOnLine } from '../hooks/useNavigatorOnline';
import { Box } from '@mui/material';

export const NetworkStatusIndicator = () => {
  const isOnline = useNavigatorOnLine();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        gap: 0.5,
        fontSize: { xs: '0.8rem', md: '1rem' },
      }}
    >
      <Box
        sx={{
          width: { xs: '0.6rem', md: '0.8rem' },
          height: { xs: '0.6rem', md: '0.8rem' },
          borderRadius: '50%',
          backgroundColor: isOnline ? '#00ee4b' : '#ff0000',
          position: 'relative',
          top: '1px',
        }}
      ></Box>
      {isOnline ? 'online' : 'offline'}
    </Box>
  );
};
