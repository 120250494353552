import { Container } from '@mui/material';
import UsersList from '../../features/users/UsersList';

const Users = () => {
  return (
    <Container component="main" maxWidth="xs">
      <UsersList />
    </Container>
  );
};

export default Users;
