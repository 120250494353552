export enum RoutesEnum {
  login = 'login',
  forgotPwd = 'forgot-password',
  users = 'users',
  harvest = 'harvest',
  addHarvest = 'harvest/add',
  addUser = 'users/add',
  inventoryWriteOff = 'inventory-write-off',
  inventoryWriteOffAdd = 'inventory-write-off/add',
  profile = 'profile',
  references = 'references',
  chooseRole = 'choose-role',
  changePwd = 'profile/change-password',
  changeEmail = 'confirm',
  notFound = '404',
}
