import { useNavigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { FormEvent, useRef, useState } from 'react';
import { Errortype, ReferenceOptionsType } from '../../types/common';
import { User } from '../../types/users';
import { useGetReferencesQuery } from '../references/referencesApiSlice';
import { useAddInventoryMutation } from './inventoryApiSlice';

interface IProps {
  data?: User;
}

const InvenroryAddForm = ({ data }: IProps) => {
  const {
    data: fields,
    isLoading: isLoadingFields,
    isError: isErrorFields,
    error: errorFields,
  } = useGetReferencesQuery({ slug: 'fields' });

  const {
    data: tmcRefs,
    isLoading: isLoadingTmcRefs,
    isError: isErrorTmcRefs,
    error: errorTmcRefs,
  } = useGetReferencesQuery({ slug: 'inventory' });

  const [addInventory, { isLoading: isAdding }] = useAddInventoryMutation();

  const navigate = useNavigate();

  const errRef = useRef<HTMLParagraphElement | null>(null);

  const [field, setField] = useState<(ReferenceOptionsType | null)[]>([null]);
  const [tmcRef, setTmcRef] = useState<ReferenceOptionsType>();

  const [formChecked, setFormChecked] = useState(false);
  const [err, setErr] = useState<Errortype>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormChecked(true);

    try {
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErr({ data: { detail: 'No Server Response' } });
      } else {
        !!err.data?.detail ? setErr(err) : setErr({ data: { detail: 'Request failed' } });
      }
      errRef.current?.focus();
    }
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
        }}
      ></Box>
    </>
  );
};

export default InvenroryAddForm;
