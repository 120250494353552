import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addError, deleteLastError } from '../features/errors/errorSlice';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../constants/routes';
import { determineIfIsSerializedErr } from '../utils/text';

export const useGlobalError = (
  isError: boolean,
  error: SerializedError | FetchBaseQueryError | undefined,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isError) {
      if (error && !determineIfIsSerializedErr(error) && error.status === 404) {
        navigate('/' + RoutesEnum.notFound);
      }
      dispatch(addError(error));
      timeout = setTimeout(() => dispatch(deleteLastError()), 3 * 3600);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        dispatch(deleteLastError());
      }
    };
  }, [isError]);

  return;
};
